.default .about {

    .text-summary {
        max-width: 50%;
        height: fit-content;
        margin-right: auto;
        margin-left: auto;
        margin-top: 2em;

        h2 {
            font-size: 1.5em;
        }

        p {
            font-size: 1em;
        }
    }
}