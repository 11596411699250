@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
}

:root {
    --bg-color: #FFF2F4;
    --main-text-color: #7B0828;
    --secondary-text-color: #FC440F;
}

body {
    background-color: var(--bg-color);
    font-size: 1.5rem;
}

p, span, a, h2 {
    color: var(--main-text-color);
}

h2 {
    font-weight: 700;
}

a, a:visited, a:hover {
    color: var(--main-text-color);
    text-decoration: none;
}

nav {
    width: 100%;
    height: fit-content;
    position: relative;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 5fr 1fr;
    grid-template-areas: 
        ". name ."
        "logo links .";
    margin-top: 3vh;
    margin-bottom: 0.6vh;

    .nav-logo {
        grid-area: logo;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        img {
            max-width: 5vw;
            max-height: 5vw;
            width: auto;
            height: auto;
            border-radius: 50%;
        }

        // .name {
        //     font-size: 1.3vw;
        //     text-align: center;
        // }

        // .flavorText {
        //     color: var(--secondary-text-color);
        //     font-size: 0.6vw;
        // }
    }

    .nav-links {
        margin-top: auto;
        margin-bottom: auto;

        grid-area: links;
        display: flex;
        justify-content: space-evenly;

        font-size: 2.5vw;
    }

    .nav-title {
        grid-area: name;
        width: 100%;

        .name {
            width: 100%;
            font-size: 3.5vw;
            text-align: center;

            display: table;
            margin: 0 auto;
        }

        .flavorText {
            color: var(--secondary-text-color);
            font-size: 1.5vw;

            display: table;
            margin: 0 auto;
        }
    }
}
